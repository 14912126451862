import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

@Component({
  selector: 'mpcm-key-value-row',
  standalone: true,
  templateUrl: './key-value-row.component.html',
  styleUrl: './key-value-row.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyTooltipModule],
})
export class KeyValueRowComponent {
  readonly name: InputSignal<string> = input.required<string>();
  readonly value: InputSignal<string> = input.required<string>();
  readonly additionalValue: InputSignal<string | undefined> = input<string | undefined>(undefined);
}
