<div class="article-name" [matTooltip]="articleName | stripHTML | stripQuotes">
  <mp-article-type-icon
    class="article-name__article-type-icon"
    [type]="articleType"
    [small]="true"
    [hasMatch]="!!isMatchedClinicArticle"
  />

  <span class="article-name__text">
    {{ articleName | stripHTML | stripQuotes }}
  </span>
</div>
