@if (condition().contract?.daysOfPayment; as contractDaysOfPayment) {
  <mat-list class="discounts-info-list">
    <mat-list-item class="discounts-info-list__item">
      <ng-template #termsOfPaymentLabel>
        <div class="terms-of-payment-label truncate">
          <mat-icon class="terms-of-payment-label__icon material-icons-outlined">crisis_alert</mat-icon>
          <span class="truncate" title="Zahlungsziel">Zahlungsziel</span>
        </div>
      </ng-template>

      <mp-param-wrapper
        class="mp-param-wrapper--horizontal mp-param-wrapper--full-width param-wrapper"
        [labelTemplate]="termsOfPaymentLabel"
      >
        <div class="param-wrapper__value truncate" [title]="contractDaysOfPayment + ' Tage'">
          {{ contractDaysOfPayment }} Tage
        </div>
      </mp-param-wrapper>
    </mat-list-item>
  </mat-list>
}

@if (condition().contract?.discounts?.length) {
  <table class="discounts-table" mat-table [dataSource]="discountsDataSource()" [trackBy]="trackByIndex">
    <ng-container matColumnDef="days">
      <mat-header-cell class="discounts-table__header-cell discounts-table__header-cell-days" *matHeaderCellDef>
        <span class="truncate" title="Skonto-Tage">Skonto-Tage</span>
      </mat-header-cell>
      <mat-cell class="discounts-table__cell discounts-table__cell-days" *matCellDef="let element">
        <span class="truncate" [title]="element.days + ' Tage'">{{ element.days }} Tage</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="percent">
      <mat-header-cell
        class="discounts-table__header-cell discounts-table__header-cell-percent truncate justify-end"
        *matHeaderCellDef
      >
        <span class="truncate" title="Prozent">Prozent</span>
      </mat-header-cell>
      <mat-cell class="discounts-table__cell discounts-table__cell-percent justify-end" *matCellDef="let element">
        <ng-container *ngrxLet="element.percent | percent: '1.0-2' as discountPercent">
          <span class="truncate" [title]="discountPercent">{{ discountPercent }}</span>
        </ng-container>
      </mat-cell>
    </ng-container>

    <mat-header-row class="discounts-table__header-row" *matHeaderRowDef="displayedCols" />
    <mat-row class="discounts-table__row" *matRowDef="let row; columns: displayedCols" />
  </table>
}
