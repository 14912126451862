import { ChangeDetectionStrategy, Component, InputSignal, OutputEmitterRef, input, output } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { ExportJobProvider } from '@mp/content-manager/exports/data-access';

@Component({
  selector: 'mpcm-export-articles-menu',
  standalone: true,
  templateUrl: './export-articles-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatMenuModule, MatButtonModule],
})
export class ExportArticlesMenuComponent {
  readonly exportJobProviders: InputSignal<readonly ExportJobProvider[]> =
    input.required<readonly ExportJobProvider[]>();

  readonly isDisabled: InputSignal<boolean> = input.required<boolean>();

  readonly exportTypeSelect: OutputEmitterRef<string> = output<string>();

  selectExportType(exportType: string): void {
    this.exportTypeSelect.emit(exportType);
  }
}
