import { Pipe, PipeTransform } from '@angular/core';

import { CategoryBase } from '../models';

@Pipe({ name: 'mpcmCategoryBreadcrumb', standalone: true })
export class CategoryBreadcrumbPipe implements PipeTransform {
  transform(crumb?: CategoryBase): Array<CategoryBase> | undefined {
    if (!crumb) {
      return undefined;
    }

    const crumbs: Array<CategoryBase> = [];

    do {
      crumbs.unshift(crumb);
      crumb = crumb.parent;
    } while (crumb);

    return crumbs;
  }
}
