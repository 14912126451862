import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class PublishPlatformCatalogStatusService {
  private readonly isPlatformCatalogPublishingApiPath = '/api/contentmanagement/platformcatalogs/ispublishing';

  constructor(private readonly http: HttpClient) {}

  /**
   * Checks if the default platform catalog is currently publishing
   * Prüft, ob der Standard-Plattformkatalog gerade veröffentlicht wird
   */
  isPlatformCatalogPublishing(): Observable<boolean> {
    return this.http.get<boolean>(this.isPlatformCatalogPublishingApiPath);
  }
}
