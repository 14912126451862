import { AsyncPipe, NgIf } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { Actions, RechtFilter, Resources } from '@mp/shared/data-access';
import { UserInfoModule } from '@mp/shared/profil/data-access';
import { Observable } from 'rxjs';
import { ArticleCounts } from '../../../models';
import { ArticleCountsBaseWidgetComponent } from '../article-counts-base-widget/article-counts-base-widget.component';
import { ContentManagerWidgetStore } from './content-management-widget.store';

@Component({
  selector: 'mpcm-content-management-widget',
  standalone: true,
  templateUrl: './content-management-widget.component.html',
  styleUrls: ['./content-management-widget.component.scss'],
  imports: [NgIf, AsyncPipe, ArticleCountsBaseWidgetComponent, UserInfoModule],
  providers: [ContentManagerWidgetStore],
})
export class ContentManagementWidgetComponent {
  readonly useContentManagementArticleRight$: RechtFilter = {
    resource: Resources.ContentManagementArticle,
    action: Actions.Use,
  } as const;

  readonly articleCounts$: Observable<ArticleCounts | undefined> = this.store.articleCounts$;

  constructor(private readonly store: ContentManagerWidgetStore) {
    this.store.fetchArticleCounts();
  }

  handleSearch(searchTerm: string): void {
    this.store.navigateToArticleSearch(searchTerm);
  }
}
