import { ChangeDetectionStrategy, Component, HostBinding, InputSignal, input } from '@angular/core';

import { ArticlePackagingStatusTranslationPipe } from '../../../articlesearch';
import { ArticlePackagingStatus } from '../../../models';

@Component({
  selector: 'mpcm-article-packaging-status',
  standalone: true,
  templateUrl: './article-packaging-status.component.html',
  styleUrls: ['./article-packaging-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ArticlePackagingStatusTranslationPipe],
})
export class ArticlePackagingStatusComponent {
  readonly articlePackagingStatus: InputSignal<ArticlePackagingStatus> = input.required<ArticlePackagingStatus>();
  readonly isShortTitle: InputSignal<boolean> = input<boolean>(false);

  readonly componentCssClass = 'mp-article-packaging-status';

  @HostBinding('class') get class(): string {
    return `${this.componentCssClass} ${this.componentCssClass}--${this.articlePackagingStatus().toLowerCase()}`;
  }
}
