import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PublishPlatformCatalogStatusService } from './publish-platform-catalog-status.service';

@Injectable()
export class PublishPlatformCatalogStatusFacade {
  private readonly _isPublishingPlatformCatalog$ = new BehaviorSubject(false);
  readonly isPublishingPlatformCatalog$ = this._isPublishingPlatformCatalog$.asObservable();

  constructor(private readonly service: PublishPlatformCatalogStatusService) {}

  loadIsPlatformCatalogPublishing(): void {
    this.service.isPlatformCatalogPublishing().subscribe({
      next: (isPublishing) => {
        this._isPublishingPlatformCatalog$.next(isPublishing);
      },
    });
  }
}
