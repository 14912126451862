export enum SearchQueryField {
  /**
   * Alle Felder durchsuchen
   */
  All = 'All',

  /**
   * Nach Artikelbezeichnung filtern
   */
  ArticleName = 'ArticleName',

  /**
   * Nach Artikelbeschreibung filtern
   */
  ArticleDescription = 'ArticleDescription',

  /**
   * Nach Warengruppen Code filtern
   */
  Categories = 'Categories',

  /**
   * Nach Packungsidentifikationen filtern
   */
  PackagingUnitIdentifications = 'PackagingUnitIdentifications',

  /**
   * Nach Klinikartikelnummer filtern
   */
  ClinicArticleNumber = 'ClinicArticleNumber',

  /**
   * Nach Herstellerartikelnummer filtern
   */
  ManufacturerArticleNumber = 'ManufacturerArticleNumber',

  /**
   * Nach Lieferantenartikelnummer filtern
   */
  SupplierArticleNumber = 'SupplierArticleNumber',

  /**
   * Nach Hersteller filtern
   */
  ManufacturerName = 'ManufacturerName',

  /**
   * Nach Lieferant filtern
   */
  SupplierName = 'SupplierName',

  /**
   * Pharmaceutical ingredients of article
   */
  PharmaceuticalIngredients = 'PharmaceuticalIngredients',
}
