type PriceLocaleStringOptions = Omit<Intl.NumberFormatOptions, 'style' | 'currency'>;

export function getArticlePriceLocaleString(price: number | undefined, options: PriceLocaleStringOptions = {}): string {
  if (!price) {
    return '';
  }

  return price.toLocaleString('de-DE', {
    style: 'currency',
    currency: 'EUR',
    ...options,
  });
}

export function getArticleStrictPriceLocaleString(
  price: number | undefined,
  options: PriceLocaleStringOptions = {},
): string {
  return getArticlePriceLocaleString(price, { minimumFractionDigits: 5, maximumFractionDigits: 5, ...options });
}
