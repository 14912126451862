import { NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  OnInit,
  WritableSignal,
  effect,
  input,
  signal,
  untracked,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { LocalStorageService } from '@core/shared/data-access';
import { ExpandablePanelComponent } from '@core/ui';

import { KeyValueRowComponent } from '../key-value-row/key-value-row.component';

import { KeyValue } from './key-value';

@Component({
  selector: 'mpcm-key-value-panel',
  standalone: true,
  templateUrl: './key-value-panel.component.html',
  styleUrl: './key-value-panel.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MatIconModule, KeyValueRowComponent, ExpandablePanelComponent],
})
export class KeyValuePanelComponent implements OnInit {
  readonly headerName: InputSignal<string | undefined> = input<string | undefined>();

  readonly panelLabel: InputSignal<string> = input.required<string>();

  readonly keyValuesList: InputSignal<KeyValue[]> = input.required<KeyValue[]>();

  readonly expansionStateKey: InputSignal<string> = input.required<string>();

  readonly isPanelHighlighted: InputSignal<boolean> = input<boolean>(false);

  readonly isPanelOpened: WritableSignal<boolean> = signal<boolean>(false);

  constructor(private readonly localStorageService: LocalStorageService) {
    effect(() => {
      const expansionStateKey: string = untracked(() => this.expansionStateKey());
      this.localStorageService.write(expansionStateKey, this.isPanelOpened());
    });
  }

  ngOnInit(): void {
    this.restoreExpansionStateFromStorage();
  }

  private restoreExpansionStateFromStorage(): void {
    const expansionState: boolean = this.getStoredExpansionState();
    this.isPanelOpened.set(expansionState);
  }

  private getStoredExpansionState(): boolean {
    const expansionStateKey: string = this.expansionStateKey();
    return this.localStorageService.read(expansionStateKey, false);
  }
}
