import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  InputSignalWithTransform,
  Signal,
  computed,
  input,
} from '@angular/core';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

import { ArticleProperty } from '@mp/content-manager/artikelsuche/data-access';

import { CategoryType, categoryTypeDescriptions } from '../../../categories';
import { ArticleTag } from '../../../models';
import { ArticleAttributes, ArticleCategoriesWithProperties } from '../../models';
import { buildTagArray } from '../../utils';
import { CategoryCardComponent } from '../category-card/category-card.component';
import { KeyValue } from '../key-value-panel/key-value';
import { KeyValuePanelComponent } from '../key-value-panel/key-value-panel.component';

function emptyArrayIfUndefined<T>(value: T[] | undefined): T[] {
  return value ?? [];
}

@Component({
  selector: 'mpcm-tab-merkmale',
  standalone: true,
  templateUrl: './tab-merkmale.component.html',
  styleUrl: './tab-merkmale.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatCardModule, CategoryCardComponent, KeyValuePanelComponent],
})
export class TabMerkmaleComponent {
  readonly categoriesWithProperties: InputSignalWithTransform<
    ArticleCategoriesWithProperties[],
    ArticleCategoriesWithProperties[] | undefined
  > = input<ArticleCategoriesWithProperties[], ArticleCategoriesWithProperties[] | undefined>([], {
    transform: emptyArrayIfUndefined,
  });

  readonly metadataProperties: InputSignalWithTransform<ArticleProperty[], ArticleProperty[] | undefined> = input<
    ArticleProperty[],
    ArticleProperty[] | undefined
  >([], { transform: emptyArrayIfUndefined });

  readonly articleTags: InputSignalWithTransform<ArticleTag[], ArticleTag[] | undefined> = input<
    ArticleTag[],
    ArticleTag[] | undefined
  >([], { transform: emptyArrayIfUndefined });

  readonly articleAttributes: InputSignal<ArticleAttributes | undefined> = input<ArticleAttributes>();

  readonly basePath: InputSignal<string[]> = input.required<string[]>();

  protected readonly metadataPropertiesKeyValuesList: Signal<KeyValue[]> = computed(() =>
    this.getMetadataPropertiesKeyValuesList(this.metadataProperties()),
  );

  protected readonly tagsKeyValuesList: Signal<KeyValue[]> = computed(() =>
    this.getTagsKeyValuesList(this.articleTags()),
  );

  protected readonly tagsHighlighted: Signal<boolean> = computed(() => !!this.articleAttributes()?.['tags']);

  protected readonly categoryTypeDescriptions: Record<CategoryType, string> = categoryTypeDescriptions;

  protected readonly CATEGORY_PROPERTIES_EXPANSION_STATE_KEY = 'CM_ARTICLE_FEATURES_CATEGORY_PROPERTIES_EXPANDED';

  protected readonly METADATA_PROPERTIES_EXPANSION_STATE_KEY = 'CM_ARTICLE_FEATURES_METADATA_PROPERTIES_EXPANDED';

  protected readonly TAGS_EXPANSION_STATE_KEY = 'CM_ARTICLE_FEATURES_TAGS_EXPANDED';

  private getMetadataPropertiesKeyValuesList(metadataArticleProperties: ArticleProperty[]): KeyValue[] {
    return metadataArticleProperties.map(({ name, value }) => ({ key: name, value }));
  }

  private getTagsKeyValuesList(articleTags: ArticleTag[]): KeyValue[] {
    return buildTagArray(articleTags).map(({ name, value }) => ({ key: name, value }));
  }
}
