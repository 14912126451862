import { Pipe, PipeTransform } from '@angular/core';

import { ArticlePackagingStatus } from '../../models';

const ARTICLE_STATUS_NAME_MAP: Record<ArticlePackagingStatus, string> = {
  [ArticlePackagingStatus.Available]: 'Aktiv',
  [ArticlePackagingStatus.Discontinued]: 'Außer Handel',
};

const ARTICLE_STATUS_SHORTNAME_MAP: Record<ArticlePackagingStatus, string> = {
  [ArticlePackagingStatus.Available]: 'A',
  [ArticlePackagingStatus.Discontinued]: 'AH',
};

@Pipe({ name: 'mpcmArticlePackagingStatusTranslation', standalone: true })
export class ArticlePackagingStatusTranslationPipe implements PipeTransform {
  transform(ArticlePackagingStatus: ArticlePackagingStatus, isShortTitle = false): string {
    if (isShortTitle) {
      return ARTICLE_STATUS_SHORTNAME_MAP[ArticlePackagingStatus];
    }
    return ARTICLE_STATUS_NAME_MAP[ArticlePackagingStatus];
  }
}
