/**
 * Tags der Packungseinheit. Gibt genauere Auskünfte über die Eigenschaften der Packungseinheit.
 *
 * ---
 * Tags of the packaging unit. Offers more detailed information regarding the properties of the packaging unit.
 */
export enum ArticlePackagingTag {
  /**
   * Ist Basismengeneinheit
   */
  IsBaseUnit = 'IsBaseUnit',
  /**
   * Ist Frakturiereinheit
   */
  IsInvoiceUnit = 'IsInvoiceUnit',
  /**
   * Ist Verbrauchseinheit
   */
  IsConsumptionUnit = 'IsConsumptionUnit',
  /**
   * Ist mengenvariabler Artikel
   */
  IsVariableUnit = 'IsVariableUnit',
  /**
   * Ist Versandeinheit
   */
  IsDispatchUnit = 'IsDispatchUnit',
  /**
   * Bestellfähigkeit der Verpackungsstufe/Versandeinheit
   */
  IsOrderableUnit = 'IsOrderableUnit',
  /**
   * Ist Artikel unverpackt
   */
  IsUnpackaged = 'IsUnpackaged',
  /**
   * Wurde Artikel mit bestimmten Kunden verhandelt
   */
  IsCustomerSpecific = 'IsCustomerSpecific',
  /**
   * Ist Standardbestellmengeneinheit
   */
  IsStandardOrderUnit = 'IsStandardOrderUnit'
}
