import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mpcmDeleteArticlesLabel',
  standalone: true,
})
export class DeleteArticlesLabelPipe implements PipeTransform {
  transform(searchTerm: string | null, count: number): string {
    return searchTerm ? `Alle ${count} bestätigen` : 'Alle bestätigen';
  }
}
