export enum CategoryType {
  /**
   * ECLASS anzeigen
   */
  ECLASS = 'ECLASS',

  /**
   * EKK anzeigen
   */
  EKK = 'EKK',

  /**
   * ATC anzeigen
   */
  ATC = 'ATC',

  /**
   * AGKAMED anzeigen
   */
  AGKAMED = 'AGKAMED',

  /**
   * AGKAMED Fachbereiche anzeigen
   */
  DEPARTMENT = 'DEPARTMENT',
}

export const categoryTypeDescriptions: Record<CategoryType, string> = {
  [CategoryType.ECLASS]: 'ECLASS',
  [CategoryType.ATC]: 'ATC-DDD',
  [CategoryType.AGKAMED]: 'AGKclassMedical',
  [CategoryType.DEPARTMENT]: 'AGKA-Fachbereich',
  [CategoryType.EKK]: 'ClassEKK+',
};
