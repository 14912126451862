import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { getImagePath } from '@core/shared/util';
import { ImageComponent } from '@core/ui';
import { ApiUrlService } from '@mp/shared/data-access';

/**
 * Component to display a (currently the first) image of an article.
 */
@Component({
  selector: 'mpcm-article-image',
  standalone: true,
  templateUrl: './article-image.component.html',
  styleUrls: ['./article-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ImageComponent],
})
export class ArticleImageComponent {
  private _globalArticleId?: string | null;
  private _articleRecordId?: string | null;
  private _imagePath?: string | null;

  @HostBinding('class')
  readonly class = 'mpcm-article-image';

  /**
   * The identifier of the article.
   */
  get globalArticleId(): string | null {
    return this._globalArticleId || null;
  }
  // @Input()
  set globalArticleId(value: string | null | undefined) {
    this._globalArticleId = value || null;
  }

  /**
   * If specified, displays the image of the platform article that is an article reference
   * of the specified global article. Works only if `globalArticleId` is specified.
   */
  // @Input()
  set articleRecordId(value: string | null | undefined) {
    this._articleRecordId = value || null;
  }

  /**
   * The alternative text to display.
   */
  @Input() alternateText?: string;

  constructor(private readonly apiUrlService: ApiUrlService) {}

  get imagePath(): string {
    if (this._imagePath) {
      return getImagePath(this._imagePath);
    }

    if (!this._globalArticleId) {
      return '';
    }

    const path = this._articleRecordId ? `${this._globalArticleId}/${this._articleRecordId}` : this._globalArticleId;

    return this.apiUrlService.getApiUrl(`/api/contentmanagement/articles/images/${path}`, false);
  }

  /**
   * Fallback due to issue #43482 to use static path.
   */
  @Input()
  set imagePath(value: string | null | undefined) {
    this._imagePath = value ?? null;
  }
}
