import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  Signal,
  WritableSignal,
  computed,
  effect,
  input,
  signal,
} from '@angular/core';

import { LocalStorageService } from '@core/shared/data-access';
import { DataSectionComponent, ExpandablePanelComponent, InfoCardComponent } from '@core/ui';

import { ArticleCondition } from '../../../models';

import { ArticleConditionDiscountsComponent } from './article-condition-discounts/article-condition-discounts.component';
import { ConditionOrderingConditionsComponent } from './condition-ordering-conditions/condition-ordering-conditions.component';

const CONDITION_EXTENDED_INFO_EXPANDED_KEY = 'CM_ARTICLE_CONDITION_ADDITIONAL_INFO_EXPANDED';

@Component({
  selector: 'mpcm-article-condition-additional-info',
  standalone: true,
  templateUrl: './article-condition-additional-info.component.html',
  styleUrl: './article-condition-additional-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DataSectionComponent,
    InfoCardComponent,
    ArticleConditionDiscountsComponent,
    ConditionOrderingConditionsComponent,
    ExpandablePanelComponent,
  ],
})
export class ArticleConditionAdditionalInfoComponent {
  readonly condition: InputSignal<ArticleCondition> = input.required<ArticleCondition>();

  protected readonly isPanelExpanded: WritableSignal<boolean> = signal<boolean>(
    this.localStorageService.read(CONDITION_EXTENDED_INFO_EXPANDED_KEY, false),
  );

  protected readonly showOrderingConditionsSection: Signal<boolean> = computed(() =>
    this.isOrderingConditionsSectionVisible(),
  );

  protected readonly showDiscountsSection: Signal<boolean> = computed(() => this.isDiscountsSectionVisible());

  constructor(private readonly localStorageService: LocalStorageService) {
    effect(() => this.localStorageService.write(CONDITION_EXTENDED_INFO_EXPANDED_KEY, this.isPanelExpanded()));
  }

  private isOrderingConditionsSectionVisible(): boolean {
    const hasMinimumOrderValue = !!this.condition().minimumOrderValue;
    const hasContractMinimalOrderValue = !!this.condition().contract?.minimalOrderValue;

    return hasMinimumOrderValue || hasContractMinimalOrderValue;
  }

  private isDiscountsSectionVisible(): boolean {
    const hasDaysOfPaymentValue = !!this.condition().contract?.daysOfPayment;
    const hasAnyDiscounts = !!this.condition().contract?.discounts?.length;

    return hasDaysOfPaymentValue || hasAnyDiscounts;
  }
}
