import { ChangeDetectionStrategy, Component, InputSignal, WritableSignal, effect, input, signal } from '@angular/core';

import { LocalStorageService } from '@core/shared/data-access';
import { ExpandablePanelComponent } from '@core/ui';

import { ArticleContractAgreement } from '../../../models';

import { ArticleConditionAgreementComponent } from './article-condition-agreement/article-condition-agreement.component';

const CONDITION_AGREEMENTS_EXPANDED_KEY = 'CM_ARTICLE_CONDITION_AGREEMENTS_EXPANDED';

@Component({
  selector: 'mpcm-article-condition-agreements',
  standalone: true,
  templateUrl: './article-condition-agreements.component.html',
  styleUrl: './article-condition-agreements.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ExpandablePanelComponent, ArticleConditionAgreementComponent],
})
export class ArticleConditionAgreementsComponent {
  readonly agreements: InputSignal<ArticleContractAgreement[]> = input.required<ArticleContractAgreement[]>();

  protected readonly isPanelExpanded: WritableSignal<boolean> = signal<boolean>(
    this.localStorageService.read(CONDITION_AGREEMENTS_EXPANDED_KEY, false),
  );

  constructor(private readonly localStorageService: LocalStorageService) {
    effect(() => this.localStorageService.write(CONDITION_AGREEMENTS_EXPANDED_KEY, this.isPanelExpanded()));
  }
}
