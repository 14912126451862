<mpcm-export-articles-menu
  [exportJobProviders]="exportJobProviders()"
  [isDisabled]="isExportDisabled()"
  (exportTypeSelect)="selectExportType($event)"
>
  <button
    class="export-articles-button"
    mat-raised-button
    [disabled]="isExportDisabled()"
    [matTooltip]="tooltipText() || ''"
    [matTooltipDisabled]="!isExportDisabled() || !tooltipText()"
  >
    <mat-icon class="material-icons-outlined">{{ isDisabled() ? 'warning_amber' : 'login' }}</mat-icon>
    Export
  </button>
</mpcm-export-articles-menu>
