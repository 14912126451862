<mp-inline-edit #inlineEdit [value]="favoritesList.name" (valueSubmit)="onUpdateClick(favoritesList.id, $event)">
  <div class="favorites-list">
    <span class="favorites-list__name truncate" [title]="favoritesList.name">
      {{ favoritesList.name }}
    </span>

    <span class="favorites-list__count">{{ favoritesList.articleCount }}</span>
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="inlineEdit.showInputField()">
        <mat-icon>edit</mat-icon>
        Bearbeiten
      </button>

      <button mat-menu-item (click)="onDeleteClick(favoritesList.id)">
        <mat-icon>delete</mat-icon>
        Löschen
      </button>
    </mat-menu>
  </div>
</mp-inline-edit>
