import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'mpcmAdditionalStatusDateTooltip', standalone: true })
export class AdditionalStatusDateTooltipPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(additionalStatusDate: string | undefined): string {
    if (additionalStatusDate) {
      const validFromAsString = formatDate(additionalStatusDate, 'dd.MM.yyyy', this.locale);

      return `seit/ab ${validFromAsString}`;
    }

    return '';
  }
}
