import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ThemeAssetsPathPipe } from '@core/shared/util';

@Component({
  selector: 'mpcm-logo',
  standalone: true,
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, ThemeAssetsPathPipe],
})
export class LogoComponent {
  @Input() fileName = '';

  @Input() alternativeText = 'Logo';
}
