import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@Component({
  selector: 'mpcm-clinic-article-number',
  standalone: true,
  templateUrl: './clinic-article-number.component.html',
  styleUrls: ['./clinic-article-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTooltipModule, MatIconModule],
})
export class ClinicArticleNumberComponent {
  @HostBinding('class') readonly class = 'mpcm-clinic-article-number';

  @Input()
  clinicArticleNumber = '';
}
