/**
 * The possible additional article status.
 */
export enum AdditionalArticleStatus {
  CustomerSpecific = 'CustomerSpecific',
  Sale = 'Sale',
  NoContractPartner = 'NoContractPartner',
  PriceOnRequest = 'PriceOnRequest',
  FreeArticle = 'FreeArticle',
}
