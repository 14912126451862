<ul class="condition-info-list">
  <li class="condition-info-list__row">
    <ng-content select="[primary-condition]" />
  </li>

  @if (condition().baseCondition; as baseCondition) {
    <li
      class="condition-info-list__row"
      [ngClass]="{
        'condition-info-list__row--current-signed': baseCondition.isSignedCondition && !isFutureCondition(),
        'condition-info-list__row--future-signed': baseCondition.isSignedCondition && isFutureCondition()
      }"
    >
      @if (baseCondition.requirements; as baseConditionRequirements) {
        <mp-param-wrapper
          class="mp-param-wrapper--horizontal condition-info-list__row-param"
          [label]="condition().baseConditionName ? condition().baseConditionName + ':' : ''"
          [boldLabel]="true"
        >
          <span class="condition-info-list__value font-bold">
            <span class="condition-info-list__value-prefix">Ab</span>
            {{ baseConditionRequirements[0] | mpGraduationValueOutput: condition() }}

            <mp-graduation-effect
              class="condition-info-list__effect-value"
              [graduationLevel]="baseCondition"
              [isFutureCondition]="isFutureCondition()"
              [showValidityDatesTooltip]="true"
            />
          </span>
        </mp-param-wrapper>
      }
    </li>
  }

  @if (conditionPriceVatCode(); as conditionPriveVatCode) {
    <li class="condition-info-list__row">
      <mp-param-wrapper class="mp-param-wrapper--horizontal condition-info-list__row-param" label="Steuersatz:">
        <span class="font-bold">{{ conditionPriveVatCode | mpcmConditionPriceVatCode }}</span>
      </mp-param-wrapper>
    </li>
  }

  @if (condition().validFrom; as validFrom) {
    <li class="condition-info-list__row">
      <mp-param-wrapper class="mp-param-wrapper--horizontal condition-info-list__row-param" label="Gültig von:">
        <span class="font-bold">{{ validFrom | date: 'dd.MM.yyyy' }}</span>
      </mp-param-wrapper>
    </li>
  }

  @if (condition().validUntil; as validUntil) {
    <li class="condition-info-list__row">
      <mp-param-wrapper class="mp-param-wrapper--horizontal condition-info-list__row-param" label="Gültig bis:">
        <span class="font-bold">{{ validUntil | date: 'dd.MM.yyyy' }}</span>
      </mp-param-wrapper>
    </li>
  }

  @if (dataSourceRight | rechtIsHeldByUser) {
    <li class="condition-info-list__row">
      <mp-param-wrapper class="mp-param-wrapper--horizontal condition-info-list__row-param" label="Datenquelle:">
        <span class="font-bold">{{ condition().dataSource }}</span>
      </mp-param-wrapper>
    </li>
  }
</ul>
