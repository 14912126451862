import { CurrencyPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { LetDirective } from '@ngrx/component';

import { ParamWrapperComponent } from '@core/ui';

@Component({
  selector: 'mpcm-ordering-conditions-field',
  standalone: true,
  templateUrl: './ordering-conditions-field.component.html',
  styleUrls: ['./ordering-conditions-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CurrencyPipe, LetDirective, ParamWrapperComponent],
})
export class OrderingConditionsFieldComponent {
  readonly label: InputSignal<string> = input.required<string>();

  readonly value: InputSignal<string | number | null> = input.required<string | number | null>();
}
