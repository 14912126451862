<mp-expandable-panel
  panelTitle="Lieferantenkonditionen"
  [(expanded)]="isPanelExpanded"
  expandButtonLabel="Alle Vereinbarungen"
>
  <ng-template>
    <div class="condition-agreements">
      @for (agreement of agreements(); track $index) {
        <mpcm-article-condition-agreement [agreement]="agreement" />
      }
    </div>
  </ng-template>
</mp-expandable-panel>
