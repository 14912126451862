import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatLegacyTooltipModule } from '@angular/material/legacy-tooltip';

import { ArticleType } from '@core/shared/domain';
import { UtilPipesModule } from '@core/shared/util';
import { ArticleTypeIconComponent } from '@core/ui';

@Component({
  selector: 'mpcm-article-name',
  standalone: true,
  templateUrl: './article-name.component.html',
  styleUrl: './article-name.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatLegacyTooltipModule, ArticleTypeIconComponent, UtilPipesModule],
})
export class ArticleNameComponent {
  @HostBinding('class') readonly class = 'mpcm-article-name';

  @Input({ required: true }) articleType!: ArticleType;

  @Input({ required: true }) articleName!: string;

  @Input() isMatchedClinicArticle?: boolean;
}
