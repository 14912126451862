import { Injectable } from '@angular/core';
import { PageResponse, Pagination } from '@mp/shared/data-access';
import { concatMap, map, Observable, of } from 'rxjs';
import {
  UploadEntryFileType,
  UploadEntry,
  UploadEntryStatus,
  CreateUploadEntryRequest,
  GenerateUploadUrlResult,
} from '../models';
import { DatasourceUploadService } from './datasource-upload.service';

interface FileUploadData {
  file: File;
  fileType: UploadEntryFileType;
  comment?: string;
}

@Injectable()
export class DatasourceUploadFacade {
  constructor(private readonly datasourceUploadService: DatasourceUploadService) {}

  uploadFile(
    { file, fileType, comment }: FileUploadData,
    onProgress?: (progress: number) => void
  ): Observable<UploadEntry> {
    return this.generateUploadUrl(file.name).pipe(
      concatMap((data) => this.uploadFileToStorage(data, file, onProgress)),
      concatMap((filePath) => this.createUploadEntry({filePath, fileType, comment}))
    );
  }

  uploadFileAttachment(
    parentFileId: string,
    file: File,
    onProgress?: (progress: number) => void
  ): Observable<UploadEntry> {
    return this.generateUploadUrl(file.name).pipe(
      concatMap((data) => this.uploadFileToStorage(data, file, onProgress)),
      concatMap((filePath) => this.createUploadAttachmentEntry(parentFileId, filePath))
    );
  }

  generateUploadUrl(
    fileName: string
  ): Observable<GenerateUploadUrlResult> {
    return this.datasourceUploadService.generateUploadUrl(fileName);
  }

  uploadFileToStorage(
    data: GenerateUploadUrlResult,
    file: File,
    onProgress?: (progress: number) => void
  ): Observable<string> {
    return of(data).pipe(
      concatMap(({ isRemote, url }) =>
        isRemote
          ? this.datasourceUploadService.uploadFileToRemoteStorage(url, file, onProgress)
          : this.datasourceUploadService.uploadFileToLocalStorage(url, file, onProgress)
      ),
      map(() => data.filePath)
    );
  }

  createUploadEntry(createUploadEntryRequest: CreateUploadEntryRequest): Observable<UploadEntry> {
    return this.datasourceUploadService.createUploadEntry(createUploadEntryRequest);
  }

  createUploadAttachmentEntry(
    parentFileId: string,
    filePath: string
  ): Observable<UploadEntry> {
    return this.datasourceUploadService.createUploadAttachmentEntry(parentFileId, filePath);
  }

  fetchUploadEntries(pagination?: Pagination): Observable<PageResponse<UploadEntry>> {
    return this.datasourceUploadService.fetchUploadEntries(pagination);
  }

  deleteUploadEntry(fileId: string): Observable<void> {
    return this.datasourceUploadService.deleteUploadEntry(fileId);
  }

  updateUploadEntryStatus(
    fileId: string,
    status: UploadEntryStatus
  ): Observable<UploadEntry> {
    return this.datasourceUploadService.updateUploadEntryStatus(fileId, status);
  }
}
