import { PercentPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule } from '@angular/material/legacy-list';
import { MatLegacyTableDataSource, MatLegacyTableModule } from '@angular/material/legacy-table';
import { LetDirective } from '@ngrx/component';

import { ParamWrapperComponent } from '@core/ui';

import { ArticleCondition, ArticleConditionContractDiscount } from '../../../../models';

@Component({
  selector: 'mpcm-article-condition-discounts',
  standalone: true,
  templateUrl: './article-condition-discounts.component.html',
  styleUrl: './article-condition-discounts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PercentPipe, LetDirective, MatLegacyListModule, MatIconModule, MatLegacyTableModule, ParamWrapperComponent],
})
export class ArticleConditionDiscountsComponent {
  readonly condition: InputSignal<ArticleCondition> = input.required<ArticleCondition>();

  readonly discountsDataSource: Signal<MatLegacyTableDataSource<ArticleConditionContractDiscount>> = computed(
    () => new MatLegacyTableDataSource<ArticleConditionContractDiscount>(this.condition().contract?.discounts || []),
  );

  readonly displayedCols: string[] = ['days', 'percent'];

  trackByIndex(index: number): number {
    return index;
  }
}
