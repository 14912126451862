import { DecimalPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  InputSignal,
  OnInit,
  Signal,
  computed,
  input,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

import { CopyToClipboardButtonComponent } from '@core/ui';

import { ArticlePackagingMeasurementType, ArticlePackagingTag } from '../../../models';
import {
  ArticleAttributes,
  ArticleMeasurement,
  ArticlePackagingUnit,
  ArticlePackagingUnitIdentification,
} from '../../models';
import { ArticlePackagingStatusComponent } from '../article-packaging-status/article-packaging-status.component';

@Component({
  selector: 'mpcm-tab-packungsinfo',
  standalone: true,
  templateUrl: './tab-packungsinfo.component.html',
  styleUrls: ['./tab-packungsinfo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    DecimalPipe,

    MatCardModule,
    MatIconModule,

    CopyToClipboardButtonComponent,
    ArticlePackagingStatusComponent,
  ],
})
export class TabPackungsinfoComponent implements OnInit {
  readonly packungsinfo: InputSignal<ArticlePackagingUnit> = input.required<ArticlePackagingUnit>();
  readonly articleAttributes: InputSignal<ArticleAttributes | undefined> = input<ArticleAttributes>();

  private readonly _packagingUnitIdKey: Signal<string> = computed(() => {
    return `packagingUnits.${this.packungsinfo().packagingUnitId}`;
  });

  readonly isWholePackagingUnitHighlighted: Signal<boolean> = computed(() => {
    return this.isPropertyHighlighted(this._packagingUnitIdKey());
  });

  readonly componentCssClass = 'mpcm-article-packaging-status';

  measurementsNet = '';
  measurementsGross = '';
  weightNet = '';
  weightGross = '';

  isBaseUnit = false;
  isOrderableUnit = false;
  isStandardOrderUnit = false;

  @HostBinding('class') get class(): string {
    return `${this.getPackagingStatusCssClass()}`;
  }

  private getPackagingStatusCssClass(): string {
    if (this.packungsinfo().packagingStatus) {
      return `${this.componentCssClass} ${this.componentCssClass}--${this.packungsinfo().packagingStatus?.toLowerCase()}`;
    }
    return `${this.componentCssClass}`;
  }

  ngOnInit(): void {
    this.populate();
  }

  private populate(): void {
    this.buildMeasurementValues(this.packungsinfo());
    this.setUnitBooleans(this.packungsinfo().tags);
  }

  private buildMeasurementValues(packungsinfo?: ArticlePackagingUnit): void {
    packungsinfo?.measurements?.forEach((measurement) => {
      if (measurement.type === ArticlePackagingMeasurementType.Article) {
        this.measurementsNet = this.buildMeasurementString(measurement);
        this.weightNet = this.buildWeightString(measurement);
      } else {
        this.measurementsGross = this.buildMeasurementString(measurement);
        this.weightGross = this.buildWeightString(measurement);
      }
    });
  }

  private buildMeasurementString(measurement?: ArticleMeasurement): string {
    let returnString = '';

    if (!!measurement?.height && !!measurement?.width && !!measurement?.depth && !!measurement?.dimensionUOM) {
      returnString =
        measurement?.height?.toLocaleString('de-DE', {
          maximumFractionDigits: 2,
        }) +
        ' x ' +
        measurement?.width?.toLocaleString('de-DE', {
          maximumFractionDigits: 2,
        }) +
        ' x ' +
        measurement?.depth?.toLocaleString('de-DE', {
          maximumFractionDigits: 2,
        }) +
        ' ' +
        measurement?.dimensionUOM;
    }

    return returnString;
  }

  private buildWeightString(measurement?: ArticleMeasurement): string {
    let returnString = '';
    if (measurement?.weight && measurement?.weightUOM) {
      returnString =
        measurement.weight.toLocaleString('de-DE', {
          maximumFractionDigits: 2,
        }) +
        ' ' +
        measurement.weightUOM;
    }

    return returnString;
  }

  private setUnitBooleans(tags?: Array<ArticlePackagingTag>): void {
    this.isBaseUnit = tags?.some((tag) => tag === ArticlePackagingTag.IsBaseUnit) ?? false;
    this.isOrderableUnit = tags?.some((tag) => tag === ArticlePackagingTag.IsOrderableUnit) ?? false;
    this.isStandardOrderUnit = tags?.some((tag) => tag === ArticlePackagingTag.IsStandardOrderUnit) ?? false;
  }

  isIdentificationHighlighted(identification: ArticlePackagingUnitIdentification): boolean {
    const key = `${this._packagingUnitIdKey()}.identifications.${identification.identificationId}`;
    return !this.areAllIdentificationsHighlighted() && this.isPropertyHighlighted(key);
  }

  areAllIdentificationsHighlighted(): boolean {
    const key = `${this._packagingUnitIdKey()}.identifications`;
    return this.isPropertyHighlighted(key);
  }

  isMeasurementHighlighted(): boolean {
    const key = `${this._packagingUnitIdKey()}.measurements`;
    return this.isPropertyHighlighted(key);
  }

  private isPropertyHighlighted(attributeName: string): boolean {
    return !!this.articleAttributes()?.[attributeName];
  }
}
