import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';

import { ArticleCondition } from '../../../../models';

import { OrderingConditionsFieldComponent } from './condition-ordering-field/ordering-conditions-field.component';

@Component({
  selector: 'mpcm-condition-ordering-conditions',
  standalone: true,
  templateUrl: './condition-ordering-conditions.component.html',
  styleUrls: ['./condition-ordering-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DecimalPipe, OrderingConditionsFieldComponent],
})
export class ConditionOrderingConditionsComponent {
  readonly condition: InputSignal<ArticleCondition> = input.required<ArticleCondition>();
}
