<mat-card class="pknfo">
  <div class="main-container" [ngClass]="{ 'main-container--highlighted': isWholePackagingUnitHighlighted() }">
    <div class="pknfo__col pknfo__col--left">
      <div class="pknfo__row">
        <div class="pknfo__col">
          <div class="pknfo__unit">
            <span class="label label--nogap">Einheit</span>
            <span class="val val--big val--inline">{{ packungsinfo().unit }}</span>
            <span class="val--secondary--big">{{ packungsinfo().mappedLongName }}</span>
          </div>
        </div>
        <div class="pknfo__col">
          <div class="pknfo__unit">
            <span class="label label--nogap">Faktor in BME</span>
            <span class="val val--medium val--inline"
              >{{ packungsinfo().quantityOfBaseUnit | number }} {{ packungsinfo().baseUnit }}</span
            >
            <span class="val--secondary--medium">{{ packungsinfo().baseUnitMappedLongName }}</span>
          </div>
        </div>
      </div>
      <div class="pknfo__unit">
        <span class="pknfo__unit-grp">
          @if (isBaseUnit) {
            <mat-icon class="pknfo__unit-grp-icon">check</mat-icon>
            <span class="val truncate">Basismengeneinheit</span>
          }
        </span>
        <span class="pknfo__unit-grp pknfo__unit-grp--spaced">
          @if (isStandardOrderUnit) {
            <mat-icon class="pknfo__unit-grp-icon">check</mat-icon>
            <span class="val truncate">Standardbestelleinheit</span>
          }
        </span>
        <span class="pknfo__unit-grp pknfo__unit-grp--spaced">
          @if (isOrderableUnit) {
            <mat-icon class="pknfo__unit-grp-icon">check</mat-icon>
            <span class="val truncate">Bestellbare Einheit</span>
          }
        </span>
      </div>
      @if (isWholePackagingUnitHighlighted()) {
        <mat-icon svgIcon="global_article_star" class="global-article-icon" />
      }
    </div>

    <div class="pknfo__col" [ngClass]="{ 'pknfo__col--highlighted': isMeasurementHighlighted() }">
      <div class="pknfo__unit">
        <span class="label truncate">Abmessung (HxBxT)</span>
        <span class="val">{{ measurementsNet }}</span>
      </div>
      <div class="pknfo__unit">
        <span class="label truncate">Verpackung (HxBxT)</span>
        <span class="val">{{ measurementsGross }}</span>
      </div>
      <div class="pknfo__unit">
        <span class="label">Gewicht</span>
        <span class="pknfo__unit-grp">
          <span class="label">Netto: </span>
          <span class="val">{{ weightNet }}</span>
        </span>
        <span class="pknfo__unit-grp">
          <span class="label">Brutto: </span>
          <span class="val">{{ weightGross }}</span>
        </span>
      </div>
      @if (isMeasurementHighlighted()) {
        <mat-icon svgIcon="global_article_star" class="global-article-icon" />
      }
    </div>

    <div class="pknfo__col" [ngClass]="{ 'pknfo__col--highlighted': areAllIdentificationsHighlighted() }">
      <span class="pknfo__unit pknfo__unit--list">
        <span class="label truncate">Packungsidentifikationen</span>
        @for (identification of packungsinfo().identifications; track $index) {
          <span
            class="pknfo__unit-grp"
            [ngClass]="{
              'pknfo__unit-grp--highlighted': isIdentificationHighlighted(identification)
            }"
          >
            <span class="label">{{ identification.type }}</span>
            <span class="val truncate">
              {{ identification.value }}
            </span>
            <mp-copy-to-clipboard-button [text]="identification.value" />
            @if (isIdentificationHighlighted(identification)) {
              <mat-icon svgIcon="global_article_star" class="global-article-icon" />
            }
          </span>
        }
      </span>
      @if (areAllIdentificationsHighlighted()) {
        <mat-icon svgIcon="global_article_star" class="global-article-icon" />
      }
    </div>

    @if (packungsinfo().packagingStatus; as packagingStatus) {
      <div class="pknfo__col--packaging-status">
        <mpcm-article-packaging-status [articlePackagingStatus]="packagingStatus" [isShortTitle]="true" />
      </div>
    }
  </div>
</mat-card>
