import { Pipe, PipeTransform } from '@angular/core';

import { MimeType } from '@core/shared/data-access';

@Pipe({ name: 'mpcmFileName' })
export class FileNamePipe implements PipeTransform {
  transform(): undefined;
  transform(dateipfad: string): string;
  transform(dateipfad?: string): string | undefined {
    if (!dateipfad) {
      return undefined;
    }

    const nameWithExtension = dateipfad.replace(/^.*[\\/]/, '');

    const parts = nameWithExtension.split('.');
    if (parts.length < 2) {
      return undefined;
    }

    parts.pop();
    return parts.join('.');
  }
}

@Pipe({ name: 'mpcmFileExtension' })
export class FileExtensionPipe implements PipeTransform {
  transform(dateipfad?: string, withDot = false): string | undefined {
    if (withDot) {
      const extension = this.transform(dateipfad, false);
      return extension !== undefined ? `.${extension}` : undefined;
    }

    if (!dateipfad) return undefined;

    const parts = dateipfad.split('.');
    return parts.length > 1 ? parts.pop() : undefined;
  }
}

export type HumanizedMimeType =
  | 'Bild'
  | 'Video'
  | 'Dokument'
  | 'Text'
  | 'Unbekannt';

@Pipe({ name: 'mpcmHumanizeMimeType' })
export class HumanizeMimeTypePipe implements PipeTransform {
  transform(mimeType?: MimeType): HumanizedMimeType | undefined {
    return humanizeMimeType(mimeType as MimeType);
  }
}

const FALLBACK_MIME_TYPE_ICON = 'insert_drive_file';

@Pipe({ name: 'mpcmIconizeMimeType' })
export class IconizeMimeTypePipe implements PipeTransform {
  transform(mimeType?: MimeType, fallbackIcon: string = FALLBACK_MIME_TYPE_ICON): string {
    if (!mimeType) return fallbackIcon;

    const humanizedMimeType: HumanizedMimeType = humanizeMimeType(mimeType);

    const iconLookupTable: Record<HumanizedMimeType, string> = {
      Bild: 'image',
      Video: 'movie',
      Dokument: 'description',
      Text: 'text_snippet',
      Unbekannt: FALLBACK_MIME_TYPE_ICON,
    };

    return iconLookupTable[humanizedMimeType] ?? FALLBACK_MIME_TYPE_ICON;
  }
}

export function humanizeMimeType(): undefined;
export function humanizeMimeType(mimeType: MimeType): HumanizedMimeType;
export function humanizeMimeType(
  mimeType?: MimeType
): HumanizedMimeType | undefined {
  if (!mimeType) return undefined;

  const [type, subtype] = mimeType.split('/');

  if (type === 'image') return 'Bild';
  if (type === 'video') return 'Video';
  if (type === 'text') return 'Text';

  const dokumentenSubtypes = ['pdf', 'ms-powerpoint', 'ms-excel', 'ms-word'];
  const isDocument =
    type === 'application' &&
    dokumentenSubtypes.some((docType) => subtype.includes(docType));
  if (isDocument) return 'Dokument';

  return 'Unbekannt';
}
