<mat-card
  class="article-condition-card"
  [ngClass]="{
    'article-condition-card--current': !isFutureCondition(),
    'article-condition-card--future': isFutureCondition()
  }"
>
  <div class="article-condition-card__cell">
    <h3 class="cell-title">
      <mat-icon class="cell-title__icon material-icons-outlined">history_edu</mat-icon>
      <span class="truncate" [title]="condition().conditionName">{{ condition().conditionName }}</span>

      @if (condition().externalUrl) {
        <a
          class="cell-title__external-id"
          title="Vergewissere dich, dass du bereits im IP3 eingeloggt bist."
          [href]="condition().externalUrl"
          target="_blank"
        >
          <span>{{ condition().externalId }}</span>
          <mat-icon class="cell-title__external-id-icon material-icons-outlined">open_in_new</mat-icon>
        </a>
      }
    </h3>
    <mpcm-article-condition-info-list [condition]="condition()" [isFutureCondition]="isFutureCondition()">
      <ng-content select="[article-primary-condition]" ngProjectAs="[primary-condition]" />
    </mpcm-article-condition-info-list>
  </div>

  <div class="article-condition-card__cell">
    <div class="cell-title cell-title--small"><span class="truncate">Staffelungen</span></div>
    <mp-graduations
      [graduations]="condition().graduations ?? []"
      [isFutureCondition]="isFutureCondition()"
      [showEffectColumn]="true"
    />
  </div>
</mat-card>

@if (hasConditionAdditionalData()) {
  <mpcm-article-condition-additional-info [condition]="condition()" />
}

@if (hasConditionAnyAgreements()) {
  <mpcm-article-condition-agreements [agreements]="condition().contract?.agreements!" />
}
