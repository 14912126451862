import { SearchQueryField } from '../models';

export const ARTICLE_SEARCH_FIELD_OPTIONS: { key: SearchQueryField; label: string }[] = [
  {
    key: SearchQueryField.All,
    label: 'Alle Felder',
  },
  {
    key: SearchQueryField.ArticleName,
    label: 'Artikelbezeichnung',
  },
  {
    key: SearchQueryField.ClinicArticleNumber,
    label: 'Klinikartikelnummer',
  },
  {
    key: SearchQueryField.PackagingUnitIdentifications,
    label: 'Packungsidentifikationsnummer',
  },
  {
    key: SearchQueryField.SupplierArticleNumber,
    label: 'Lieferantenartikelnummer',
  },
  {
    key: SearchQueryField.SupplierName,
    label: 'Lieferant',
  },
  {
    key: SearchQueryField.ManufacturerArticleNumber,
    label: 'Herstellerartikelnummer',
  },
  {
    key: SearchQueryField.ManufacturerName,
    label: 'Hersteller',
  },
  {
    key: SearchQueryField.Categories,
    label: 'Warengruppen Code',
  },
  {
    key: SearchQueryField.ArticleDescription,
    label: 'Artikelbeschreibung',
  },
  {
    key: SearchQueryField.PharmaceuticalIngredients,
    label: 'Wirkstoff',
  },
];
