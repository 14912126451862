/**
 * Tags des Artikels.
 *
 * ---
 * Tags of the article.
 */
export enum ArticleTag {
  IsHazMat = 'IsHazMat',
  IsNuclearMaterial = 'IsNuclearMaterial',
  IsObligatoryBatch = 'IsObligatoryBatch',
  IsObligatorySerialNumber = 'IsObligatorySerialNumber',
  IsObligatoryPharmacist = 'IsObligatoryPharmacist',
  IsObligatoryBTM = 'IsObligatoryBTM',
  IsObligatoryExpiryDate = 'IsObligatoryExpiryDate',
  IsObligatoryPrescription = 'IsObligatoryPrescription',
  ContainsRecycledMaterials = 'ContainsRecycledMaterials',
  IsReturnable = 'IsReturnable',
  IsService = 'IsService'
}
