import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';

import { FavoritesList, FavoritesListPageLinkCreator, UpdateFavoritesListEvent } from '../../models';
import { FavoritesListItemComponent } from '../favorites-list-item/favorites-list-item.component';

@Component({
  standalone: true,
  selector: 'mpcm-favorites-lists',
  templateUrl: './favorites-lists.component.html',
  styleUrls: ['./favorites-lists.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, RouterLink, RouterLinkActive, FavoritesListItemComponent],
})
export class FavoritesListsComponent {
  @Input() lists!: FavoritesList[];

  @Input() favoritesListPageLinkCreator!: FavoritesListPageLinkCreator;

  @Output() readonly listDelete: EventEmitter<string> = new EventEmitter<string>();

  @Output() readonly listUpdate: EventEmitter<UpdateFavoritesListEvent> = new EventEmitter<UpdateFavoritesListEvent>();

  onListUpdate(updateEvent: UpdateFavoritesListEvent): void {
    this.listUpdate.emit(updateEvent);
  }

  onListDelete(listId: string): void {
    this.listDelete.emit(listId);
  }

  trackByFn(_index: number, { id }: FavoritesList): string {
    return id;
  }
}
