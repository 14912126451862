import { ArticleTag } from '../../models';
import { ArticleMappedTag } from '../models';

export function buildTagArray(tags?: Array<ArticleTag>): Array<ArticleMappedTag> {
  const articleTags: Array<ArticleMappedTag> = [];
  if(tags === undefined || tags === null){
    return articleTags;
  }
  // eslint-disable-next-line complexity
  tags.forEach(entry => {
    switch(entry) {
      case ArticleTag.IsHazMat:
        articleTags.push({ tag: entry, name: 'Produktkennzeichnung gefährliche Materialien', value: 'Ja' });
        break;

      case ArticleTag.IsNuclearMaterial:
        articleTags.push({ tag: entry, name: 'Produktkennzeichnung nuklearer Sicherheitscode', value: 'Ja' });
        break;

      case ArticleTag.IsObligatoryBatch:
        articleTags.push({ tag: entry, name: 'Chargenpflichtig', value: 'Ja' });
        break;

      case ArticleTag.IsObligatorySerialNumber:
          articleTags.push({tag: entry, name: 'Seriennummerpflichtig', value: 'Ja' });
          break;

      case ArticleTag.IsObligatoryPharmacist:
        articleTags.push({ tag: entry, name: 'Apothekenpflichtig', value: 'Ja' });
        break;

      case ArticleTag.IsObligatoryBTM:
        articleTags.push({ tag: entry, name: 'Unterliegt dem BtMG', value: 'Ja' });
        break;

      case ArticleTag.IsObligatoryExpiryDate:
        articleTags.push({ tag: entry, name: 'Verfallsdatumpflichtig', value: 'Ja' });
        break;

      case ArticleTag.IsObligatoryPrescription:
        articleTags.push({ tag: entry, name: 'Rezeptpflichtig', value: 'Ja' });
        break;

      case ArticleTag.ContainsRecycledMaterials:
        articleTags.push({ tag: entry, name: 'enthält recycelte Materialien', value: 'Ja' });
        break;

      case ArticleTag.IsReturnable:
        articleTags.push({ tag: entry, name: 'Umtauschbar', value: 'Ja' });
        break;

      case ArticleTag.IsService:
        articleTags.push({ tag: entry, name: 'Artikel wird als Dienstleistung (z.B. Miete) angeboten', value: 'Ja' });
        break;

      default: break;
    }
  });

  return articleTags;
}
