import { DatePipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';

import { GraduationValueType } from '@core/shared/domain';
import { GraduationEffectComponent, GraduationValueOutputPipe, ParamWrapperComponent } from '@core/ui';
import { Actions, RechtFilter, Resources } from '@mp/shared/data-access';
import { UserInfoModule } from '@mp/shared/profil/data-access';

import { ArticleCondition, ArticleConditionGraduationValue } from '../../../models';

import { ConditionPriceVatCodePipe } from './condition-price-vat-code.pipe';

@Component({
  selector: 'mpcm-article-condition-info-list',
  standalone: true,
  templateUrl: './article-condition-info-list.component.html',
  styleUrl: './article-condition-info-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    DatePipe,

    GraduationValueOutputPipe,
    ConditionPriceVatCodePipe,
    GraduationEffectComponent,
    ParamWrapperComponent,
    UserInfoModule,
  ],
})
export class ArticleConditionInfoListComponent {
  readonly condition: InputSignal<ArticleCondition> = input.required<ArticleCondition>();

  readonly isFutureCondition: InputSignal<boolean> = input.required<boolean>();

  readonly conditionPriceVatCode: Signal<string | undefined> = computed(() => this.getConditionPriceVatCode());

  readonly dataSourceRight: RechtFilter = {
    resource: Resources.GlobalArticleDataSource,
    action: Actions.Read,
  } as const;

  private getConditionPriceVatCode(): string | undefined {
    const conditionEffect: ArticleConditionGraduationValue | undefined = this.condition().baseCondition?.effect;

    if (conditionEffect?.type === GraduationValueType.Price) {
      return conditionEffect.vatCode;
    }

    return undefined;
  }
}
