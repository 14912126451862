import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@Component({
  selector: 'mpcm-article-supplier-name',
  standalone: true,
  templateUrl: './article-supplier-name.component.html',
  styleUrl: './article-supplier-name.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTooltipModule, MatIconModule],
})
export class ArticleSupplierNameComponent {
  readonly supplierName: InputSignal<string> = input.required<string>();

  readonly isMainSupplier: InputSignal<boolean | undefined> = input<boolean>();
}
