import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CategoryBase, CategoryType, CategoryTypeDescription, CategoriesFilterQueryModel } from '../models';

@Injectable()
export class CategoriesService {
  private readonly baseUrl = 'api/contentmanagement/categories';

  constructor(private readonly http: HttpClient) {}

  /**
   * Fetches all category types
   * @returns array of category types with description
   */
  fetchCategoryTypes(): Observable<CategoryTypeDescription[]> {
    return this.http.get<CategoryTypeDescription[]>(`${this.baseUrl}/types`);
  }

  /**
   * Fetches all categories with specific type conforming to the search term
   * @param categoryType type of the category
   * @param queryModel Search criteria.
   * @returns array of categories with specific type
   */
  fetchCategoryTypeCategories(categoryType: CategoryType, queryModel: CategoriesFilterQueryModel): Observable<CategoryBase[]> {
    return this.http.get<CategoryBase[]>(`${this.baseUrl}/${categoryType}`, {
      params: {
        ...queryModel,
      },
    });
  }
}
