<span
  *ngFor="let list of lists; trackBy: trackByFn"
  class="list-item"
  routerLinkActive
  #rla="routerLinkActive"
  [routerLink]="favoritesListPageLinkCreator(list.id)"
>
  <mpcm-favorites-list-item
    [favoritesList]="list"
    [isActive]="rla.isActive"
    (listUpdate)="onListUpdate($event)"
    (listDelete)="onListDelete($event)"
  ></mpcm-favorites-list-item>
</span>
