import { Graduation, GraduationLevel } from '@core/ui';

import { ArticleContractAgreementGraduation, ArticleContractAgreementGraduationLevel } from '../../../../models';

export function mapAgreementGraduationsToGraduations(
  agreementGraduations: ArticleContractAgreementGraduation[] | null,
): Graduation[] {
  if (!agreementGraduations) {
    return [];
  }

  return agreementGraduations.map(({ graduationName, levels }) => ({
    graduationNames: [graduationName],
    levels: mapAgreementLevelsToGraduationLevels(levels),
  }));
}

function mapAgreementLevelsToGraduationLevels(
  levels: ArticleContractAgreementGraduationLevel[] | null,
): GraduationLevel[] {
  if (!levels) {
    return [];
  }

  return levels.map(({ effect, requirement }) => ({
    requirements: [requirement],
    effect,
  }));
}
