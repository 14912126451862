/**
 * The available measurement types.
 */
export enum ArticlePackagingMeasurementType {
  /**
   * Measurements of the article (net).
   */
  Article = 'Article',
  /**
   * Measurements of the packaging (gross).
   */
  ShippingUnit = 'ShippingUnit'
}
