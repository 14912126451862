<span
  class="supplier-name"
  [matTooltip]="(isMainSupplier() ? 'Standardbezugsquelle: ' : 'Bezugsquelle: ') + supplierName()"
>
  @if (isMainSupplier()) {
    <mat-icon svgIcon="main_supplier" class="supplier-name__supplier-icon" />
  } @else {
    <mat-icon class="material-icons-outlined supplier-name__supplier-icon"> local_shipping </mat-icon>
  }

  <span class="supplier-name__text">
    {{ supplierName() }}
  </span>
</span>
