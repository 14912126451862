import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Params, RouterLink } from '@angular/router';
import { CountUpDirective, CountUpOptions } from '@core/shared/util';
import { PageLink } from '@mp/shared/data-access';

@Component({
  selector: 'mpcm-count-up-stat',
  standalone: true,
  templateUrl: './count-up-stat.component.html',
  styleUrls: ['./count-up-stat.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgClass, RouterLink, MatIconModule, CountUpDirective],
})
export class CountUpStatComponent {
  @HostBinding('class') readonly class = 'mpcm-count-up-stat';

  @Input()
  statValue = 0;

  @Input()
  statLabel = '';

  @Input()
  link: PageLink;

  @Input()
  queryParams?: Params;

  @Input()
  options: CountUpOptions = CountUpOptions.Numeric;
}
