import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { getEnumValue } from '@mp/shared/data-access';

import { CategoryType, categoryTypeDescriptions } from '../../models';
import { CategoryBase } from '../../models/category-base';
import { BreadcrumbItemComponent } from '../breadcrumb-item/breadcrumb-item.component';

@Component({
  selector: 'mpcm-breadcrumb',
  standalone: true,
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatIconModule, BreadcrumbItemComponent],
})
export class BreadcrumbComponent implements OnInit {
  private _categoriesPath!: CategoryBase | undefined;
  categoryTypeDesciptions = categoryTypeDescriptions;
  categoryTypeDesciption = '';

  get categoriesPath(): CategoryBase | undefined {
    return this._categoriesPath;
  }
  @Input()
  set categoriesPath(value: CategoryBase | undefined) {
    this._categoriesPath = value;
  }

  @Input() categoryType = '';

  @Input() basePath: string[] = [];

  /**
   * Variablen zum Anzeigen/Ausblenden der einzelnen Komponenten-Elemente
   * Variables for showing/hiding individual component elements
   */
  @Input() showParents = false;
  @Input() showIcons = false;
  @Input() showAllCategoryCodes = false;
  @Input() showLastCategoryCode = false;

  ngOnInit(): void {
    const typeOfCategory = getEnumValue(CategoryType, this.categoryType);
    if (typeOfCategory !== undefined) {
      this.categoryTypeDesciption = categoryTypeDescriptions[typeOfCategory];
    }
  }
}
