<div class="breadcrumb">
  <div class="breadcrumb__type-item">
    <mat-icon class="breadcrumb__type-icon material-icons-outlined">category</mat-icon>
    <span class="breadcrumb__type-text" *ngIf="categoriesPath; else noCategoriesPath">
      {{ categoryTypeDesciptions[categoriesPath.type] }}
    </span>
  </div>

  <ng-template #noCategoriesPath>
    <span class="breadcrumb__type-text" #noCategoriesPath>{{ categoryTypeDesciption }}</span>
  </ng-template>

  <span *ngIf="categoriesPath as categoriesPath">
    <mpcm-breadcrumb-item
      [categoriesPath]="categoriesPath"
      [showParents]="showParents"
      [showLastCategoryCode]="showLastCategoryCode"
      [showAllCategoryCodes]="showAllCategoryCodes"
      [showIcons]="showIcons"
      [basePath]="basePath"
    />
  </span>
</div>
