import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

@Component({
  selector: 'mpcm-article-manufacturer-name',
  standalone: true,
  templateUrl: './article-manufacturer-name.component.html',
  styleUrl: './article-manufacturer-name.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatTooltipModule, MatIconModule],
})
export class ArticleManufacturerNameComponent {
  readonly manufacturerName: InputSignal<string> = input.required<string>();
}
