import { Pipe, PipeTransform } from '@angular/core';

import { ArticleStatus } from '../../models';

const ARTICLE_STATUS_NAME_MAP: Record<ArticleStatus, string> = {
  [ArticleStatus.Available]: 'Aktiv',
  [ArticleStatus.Discontinued]: 'Außer Handel',
};

const ARTICLE_STATUS_SHORTNAME_MAP: Record<ArticleStatus, string> = {
  [ArticleStatus.Available]: 'A',
  [ArticleStatus.Discontinued]: 'AH',
};

@Pipe({ name: 'mpcmArticleStatusTranslation', standalone: true })
export class ArticleStatusTranslationPipe implements PipeTransform {
  transform(articleStatus: ArticleStatus, isShortTitle = false): string {
    if (isShortTitle) {
      return ARTICLE_STATUS_SHORTNAME_MAP[articleStatus];
    }

    return ARTICLE_STATUS_NAME_MAP[articleStatus];
  }
}
