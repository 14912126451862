<div
  class="stat"
  [routerLink]="link"
  [queryParams]="queryParams"
  [ngClass]="{ 'stat--clickable': link !== undefined }"
>
  <div class="stat__value" [mpCountUp]="statValue" [options]="options">0</div>
  <div class="stat__label">
    <span class="stat__label-text">{{ statLabel }}</span>
    <mat-icon class="stat__label-icon" color="accent" *ngIf="link">north_east</mat-icon>
  </div>
</div>
