import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mpcmConditionPriceVatCode',
  standalone: true,
})
export class ConditionPriceVatCodePipe implements PipeTransform {
  transform(vatCode: string): string {
    const formattedVatCode: string = vatCode.replace(',', '.');

    let numberVatCode = Number(formattedVatCode);

    if (isNaN(numberVatCode)) {
      // Handle special vat codes like D0, D1, etc.
      return vatCode;
    }

    // Handle vat codes like 19
    if (numberVatCode > 1) {
      numberVatCode /= 100;
    }

    return numberVatCode.toLocaleString('de-DE', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }
}
