import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { AlertComponent } from '@core/ui';

@Component({
  selector: 'mpcm-platform-catalog-publishing-alert',
  template: `<mp-alert>
    <mat-icon mpAlertIcon>report</mat-icon>
    <h4 mpAlertTitle>Suche wird aktualisiert!</h4>
    Bis die Aktualisierung abgeschlossen ist, kann es zu unvollständigen Daten in der Suche führen.
  </mp-alert>`,
  standalone: true,
  imports: [AlertComponent, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformCatalogPublishingAlertComponent {}
