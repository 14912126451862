import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QueryParams } from '@core/shared/util';
import { PageResponse, Pagination } from '@mp/shared/data-access';
import { Observable } from 'rxjs';
import {
  CreateUploadEntryRequest,
  GenerateUploadUrlResult,
  UploadEntry,
  UploadEntryStatus,
} from '../models';
import { reportProgress } from '../rxjs-operators';

@Injectable()
export class DatasourceUploadService {
  private readonly baseUrl = '/api/contentmanagement/upload';

  private httpBackend: HttpClient;

  private readonly fileUploadProgressRequestOptions = {
    headers: { 'x-ms-blob-type': 'BlockBlob' },
    reportProgress: true,
    observe: 'events',
  } as const;

  constructor(private readonly handler: HttpBackend, private readonly http: HttpClient) {
    /**
     * Initialize a specialized HttpClient instance using the provided handler.
     * By doing so, we intentionally bypass any interceptors to ensure a direct
     * interaction with Azure Storage, which mandates the exclusion
     * of certain headers (Authorization header to be precise).
     */
    this.httpBackend = new HttpClient(this.handler);
  }

  /**
   * Generates the url for file upload
   * @param fileName The name of the file
   * @returns The generated url for upload
   */
  generateUploadUrl(fileName: string): Observable<GenerateUploadUrlResult> {
    return this.http.get<GenerateUploadUrlResult>(`${this.baseUrl}/url`, {
      params: {
        fileName,
      },
    });
  }

  /**
   * Uploads file to the provided storage url and returns upload progress info observable
   * @param storageUrl Url of the file storage
   * @param file File to upload
   * @param onProgress Callback to be called on upload progress
   * @returns Observable for the upload completion
   */
  uploadFileToRemoteStorage(
    storageUrl: string,
    file: File,
    onProgress?: (progress: number) => void
  ): Observable<void> {
    return this.httpBackend
      .put(storageUrl, file, this.fileUploadProgressRequestOptions)
      .pipe(reportProgress(onProgress));
  }

  /**
   * Uploads file to the provided on premises storage url and returns upload progress info observable
   * @param storageUrl Url of the file storage
   * @param file File to upload
   * @param onProgress Callback to be called on upload progress
   * @returns Observable for the upload completion
   */
  uploadFileToLocalStorage(
    storageUrl: string,
    file: File,
    onProgress?: (progress: number) => void
  ): Observable<void> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http
      .put(storageUrl, formData, this.fileUploadProgressRequestOptions)
      .pipe(reportProgress(onProgress));
  }

  /**
   * Creates an upload entry for the uploaded file
   * @param createUploadEntryRequest Upload entry file info
   * @returns File upload confirmation response
   */
  createUploadEntry(
    createUploadEntryRequest: CreateUploadEntryRequest
  ): Observable<UploadEntry> {
    return this.http.post<UploadEntry>(`${this.baseUrl}/entries`, createUploadEntryRequest);
  }

  /**
   * Creates an upload attachment entry for the uploaded file
   * @param parentFileId Parent file id
   * @param filePath Uploaded file path
   * @returns An updated upload entry
   */
  createUploadAttachmentEntry(
    parentFileId: string,
    filePath: string
  ): Observable<UploadEntry> {
    return this.http.put<UploadEntry>(
      `${this.baseUrl}/entries/${parentFileId}/processedfile`,
      { filePath }
    );
  }

  /**
   * Fetches a list of the uploaded files
   * @param pagination Pagination parameters
   * @returns Uploaded files response containing a paginated list of the uploaded files
   */
  fetchUploadEntries(pagination: Partial<Pagination> = {}): Observable<PageResponse<UploadEntry>> {
    const { page = 1, pageSize = 50 } = pagination;
    const params = QueryParams.build<UploadEntry>().page(page).pageSize(pageSize).toHttpParams();
    return this.http.get<PageResponse<UploadEntry>>(`${this.baseUrl}/entries`, { params });
  }

  /**
   * Deletes an upload entry based on its id.
   * @param id The id of the upload entry to delete.
   * @returns An observable that completes when the upload entry is deleted.
   */
  deleteUploadEntry(id: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/entries/${id}`);
  }

  /**
   * Updates the status of the upload entry.
   * @param id The id of the upload entry to update.
   * @param status The new status of the upload entry.
   * @returns An updated upload entry
   */
  updateUploadEntryStatus(id: string, status: UploadEntryStatus): Observable<UploadEntry> {
    return this.http.put<UploadEntry>(`${this.baseUrl}/entries/${id}/status`, { status });
  }
}
