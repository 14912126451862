import { NgModule } from '@angular/core';

import { BreadcrumbItemComponent } from './components/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { TreeFacetToCategoryMappingPipe } from './pipes';

@NgModule({
  imports: [BreadcrumbComponent, BreadcrumbItemComponent, TreeFacetToCategoryMappingPipe],
  exports: [TreeFacetToCategoryMappingPipe, BreadcrumbComponent, BreadcrumbItemComponent],
})
export class CategoriesModule {}
