import { Pipe, PipeTransform } from '@angular/core';

import { AdditionalArticleStatus } from '../../models';

const ADDITIONAL_ARTICLE_STATUS_NAME_MAP: Record<AdditionalArticleStatus, string> = {
  [AdditionalArticleStatus.CustomerSpecific]: 'Sonderanfertigung',
  [AdditionalArticleStatus.FreeArticle]: 'Kostenloser Artikel',
  [AdditionalArticleStatus.NoContractPartner]: 'Kein RV-Partner',
  [AdditionalArticleStatus.PriceOnRequest]: 'Preis auf Anfrage',
  [AdditionalArticleStatus.Sale]: 'Abverkauf',
};

const ADDITIONAL_ARTICLE_STATUS_SHORTNAME_MAP: Record<AdditionalArticleStatus, string> = {
  [AdditionalArticleStatus.CustomerSpecific]: 'SA',
  [AdditionalArticleStatus.FreeArticle]: 'KA',
  [AdditionalArticleStatus.NoContractPartner]: 'KV',
  [AdditionalArticleStatus.PriceOnRequest]: 'PA',
  [AdditionalArticleStatus.Sale]: 'AV',
};

@Pipe({ name: 'mpcmAdditionalArticleStatusTranslation', standalone: true })
export class AdditionalArticleStatusTranslationPipe implements PipeTransform {
  transform(articleStatus: AdditionalArticleStatus, isShortTitle = false): string {
    if (isShortTitle) {
      return ADDITIONAL_ARTICLE_STATUS_SHORTNAME_MAP[articleStatus];
    }

    return ADDITIONAL_ARTICLE_STATUS_NAME_MAP[articleStatus];
  }
}
