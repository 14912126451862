import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Optional, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

import { RouterFacade } from '@core/shared/data-access';
import { IconDirective } from '@core/shared/util';
import { SearchFieldModule } from '@core/ui';
import { PageLink } from '@mp/shared/data-access';

@Component({
  selector: 'mp-dashboard-widget',
  standalone: true,
  templateUrl: './dashboard-widget.component.html',
  styleUrls: ['./dashboard-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatCardModule, MatButtonModule, SearchFieldModule, IconDirective],
})
export class DashboardWidgetComponent {
  @HostBinding('class') readonly class = 'mp-dashboard-widget';

  @Input() icon?: string;
  @Input() widgetTitle?: string;
  @Input() featurePageLink: PageLink;
  @Input() showSearchField = false;
  @Input() enabledByPermission = false;
  @Input() noPermissionText = '';

  @Output() readonly search: EventEmitter<string> = new EventEmitter<string>();

  searchFieldSearchTerm = '';

  get actionTitle(): string {
    return this.enabledByPermission ? this.widgetTitle ?? '' : this.noPermissionText;
  }

  constructor(@Optional() private readonly routerFacade: RouterFacade) {}

  handleSearch(searchTerm: string): void {
    if (searchTerm.length) this.search.emit(searchTerm);
  }

  handleSearchTermChange(searchTerm: string): void {
    this.searchFieldSearchTerm = searchTerm;
  }

  onLinkClick(): void {
    if (!this.enabledByPermission) {
      return;
    }

    if (this.searchFieldSearchTerm) {
      this.handleSearch(this.searchFieldSearchTerm);
      return;
    }

    this.routerFacade?.navigate((router) =>
      router.navigate(Array.isArray(this.featurePageLink) ? this.featurePageLink : [this.featurePageLink]),
    );
  }
}
