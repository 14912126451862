<mp-inline-edit
  #inlineEdit
  (valueSubmit)="createNewList($event)"
  (inputFieldVisibilityChange)="inputFieldVisible = $event"
>
  <button
    *ngIf="embedded"
    mat-button
    class="add-list-button"
    (click)="$event.stopPropagation(); inlineEdit.showInputField()"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <button
    *ngIf="!embedded"
    mat-stroked-button
    class="add-list-button"
    (click)="$event.stopPropagation(); inlineEdit.showInputField()"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>

  <ng-template #buttonContent>
    <mat-icon class="add-list-button__icon">add</mat-icon>
    <span class="add-list-button__label">{{ buttonLabel }}</span>
  </ng-template>
</mp-inline-edit>
