<mp-expandable-panel
  panelTitle="Zusätzliche Konditionsinformationen"
  [(expanded)]="isPanelExpanded"
  expandButtonLabel="Alle Konditionsinformationen"
>
  <ng-template>
    <div class="additional-info">
      <div class="additional-info__column">
        @if (showDiscountsSection()) {
          <mp-data-section sectionTitle="Skonto">
            <mpcm-article-condition-discounts [condition]="condition()" />
          </mp-data-section>
        }

        @if (showOrderingConditionsSection()) {
          <mp-data-section sectionTitle="Bestellbedingungen">
            <mpcm-condition-ordering-conditions [condition]="condition()" />
          </mp-data-section>
        }
      </div>

      <div class="additional-info__column">
        @if (condition().contract?.termsOfDelivery?.length) {
          <mp-data-section sectionTitle="Lieferbedingungen">
            <mp-info-card>
              @for (termOfDelivery of condition().contract?.termsOfDelivery; track $index) {
                <div class="terms-of-delivery" [innerHTML]="termOfDelivery"></div>
              }
            </mp-info-card>
          </mp-data-section>
        }

        @if (condition().contract?.termsOfPayment?.length) {
          <mp-data-section sectionTitle="Zusätzliche Zahlungbedingungen">
            <mp-info-card>
              @for (termOfPayment of condition().contract?.termsOfPayment; track $index) {
                <div class="terms-of-payment" [innerHTML]="termOfPayment"></div>
              }
            </mp-info-card>
          </mp-data-section>
        }

        @if (condition().shippingSurcharges || condition().contract?.shippingSurcharges; as shippingSurcharges) {
          <mp-data-section sectionTitle="Versandzuschläge">
            <mp-info-card>
              <div class="shipping-surcharges" [innerHTML]="shippingSurcharges"></div>
            </mp-info-card>
          </mp-data-section>
        }
      </div>
    </div>
  </ng-template>
</mp-expandable-panel>
