<mpcm-article-counts-base-widget
  *ngIf="articleCounts$ | async as articleCounts"
  title="Artikelsuche"
  icon="manage_search"
  [featurePageLink]="['/artikel']"
  [data]="articleCounts"
  [enabledByPermission]="(useContentManagementArticleRight$ | rechtIsHeldByUser | async)!"
  [noPermissionText]="'Das Modul Content Manager Artikelsuche steht dir nicht zur Verfügung.'"
  (searchTermChange)="handleSearch($event)"
>
</mpcm-article-counts-base-widget>
