import { formatNumber } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mpcmExportLimit',
  standalone: true
})
export class ExportLimitPipe implements PipeTransform {
  transform(exportLimit: number): string {
    return (
      'Die Ergebnisliste darf ' +
      formatNumber(exportLimit, 'de-DE') +
      ' Elemente für den Daten-Export nicht überschreiten.'
    );
  }
}
