<mat-card *ngIf="totalDeleted > 0">
  <div class="deleted-favorites">
    <div class="deleted-favorites__header">
      <mat-icon>warning</mat-icon>
      Folgende {{ totalDeleted }} Artikel sind nicht mehr verfügbar
    </div>

    <div class="deleted-favorites__bar">
      <div class="deleted-favorites__input-wrapper input-wrapper">
        <mat-icon class="input-wrapper__search-icon">search</mat-icon>
        <input
          class="input-wrapper__input"
          type="text"
          [formControl]="searchTermControl"
          placeholder="Nach gelöschtem Artikel suchen"
        />
        <button *ngIf="searchTermControl.value" (click)="clearSearch()" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <button
        (click)="deleteAllArticles()"
        mat-stroked-button
        color="warn"
        class="delete-all-articles-button"
        [disabled]="filteredDeleted === 0"
      >
        <mat-icon>done</mat-icon>
        {{ searchTermControl.value | mpcmDeleteArticlesLabel : filteredDeleted }}
      </button>
    </div>

    <mpcm-deleted-favorites-list
      *ngIf="!deletedFavoritesLoading; else loading"
      [deletedArticles]="deletedFavorites"
      [totalRows]="filteredDeleted"
      (nextPageScroll)="loadNextPage()"
      (articleDelete)="deleteArticle($event)"
    >
    </mpcm-deleted-favorites-list>
  </div>
</mat-card>

<ng-template #loading>
  <mp-spinner></mp-spinner>
</ng-template>
