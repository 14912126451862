<span class="key truncate" [matTooltip]="name()">{{ name() }}</span>

<span class="value truncate">
  <span [matTooltip]="value()">{{ value() }}</span>

  @if (additionalValue(); as additionalValue) {
    <span class="value__additional-value truncate" [matTooltip]="additionalValue">{{ additionalValue }}</span>
  }
</span>

<div class="additional-content">
  <ng-content select="[additional-content]" />
</div>
