import { Pipe, PipeTransform } from '@angular/core';

import { TreeFacet } from '@mp/shared/facets/domain';

import { CategoryBase, CategoryType } from '../models';

@Pipe({
  name: 'mpcmTreeFacetToCategoryMapping',
  standalone: true,
})
export class TreeFacetToCategoryMappingPipe implements PipeTransform {
  transform(facetCategory: TreeFacet, categoryType = ''): CategoryBase {
    const selectedCategories: CategoryBase[] = [];
    const categories = facetCategory.root.children;

    if (categories) {
      categories.forEach((element) => {
        if (element.selected) {
          selectedCategories.push(mapBucketToCategory(element));
          mapAndAddSelectedChildrenAsParent(element);
        }
      });
    }

    /**
     *
     * @param bucket Bucket of TreeFacet to find the selected one and map it to Category
     * @returns
     */
    function mapAndAddSelectedChildrenAsParent(bucket: TreeFacet.Bucket<unknown>): void {
      if (bucket && bucket.children) {
        const child = bucket.children.find((x) => x.selected === true);

        if (child) {
          selectedCategories.push(mapBucketToCategory(child));
          return mapAndAddSelectedChildrenAsParent(child);
        }
      }

      for (let i = 1; i < selectedCategories.length; i++) {
        selectedCategories[i].parent = selectedCategories[i - 1];
      }
    }

    /**
     *
     * @param bucket Bucket that should be mapped to Category
     * @returns Returns a Category by the bucket informations
     */

    function mapBucketToCategory(bucket: TreeFacet.Bucket<unknown>): CategoryBase {
      const category: CategoryBase = {
        type: categoryType ? (categoryType as CategoryType) : CategoryType.ECLASS,
        description: bucket.label,
        code: bucket.value,
      };
      return category;
    }

    return selectedCategories[selectedCategories.length - 1];
  }
}
