import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';

import { GraduationsComponent } from '@core/ui';

import { ArticleCondition } from '../../models';

import { ArticleConditionAdditionalInfoComponent } from './article-condition-additional-info/article-condition-additional-info.component';
import { ArticleConditionAgreementsComponent } from './article-condition-agreements/article-condition-agreements.component';
import { ArticleConditionInfoListComponent } from './article-condition-info-list/article-condition-info-list.component';

@Component({
  selector: 'mpcm-article-condition',
  standalone: true,
  templateUrl: './article-condition.component.html',
  styleUrl: './article-condition.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,

    MatIconModule,
    MatCardModule,

    ArticleConditionInfoListComponent,
    ArticleConditionAdditionalInfoComponent,
    ArticleConditionAgreementsComponent,
    GraduationsComponent,
  ],
})
export class ArticleConditionComponent {
  readonly condition: InputSignal<ArticleCondition> = input.required<ArticleCondition>();

  readonly isFutureCondition: InputSignal<boolean> = input.required<boolean>();

  protected readonly hasConditionAdditionalData: Signal<boolean> = computed(
    () => !!this.condition().minimumOrderValue || !!this.condition().shippingSurcharges || !!this.condition().contract,
  );

  protected readonly hasConditionAnyAgreements: Signal<boolean> = computed(
    () => !!this.condition().contract?.agreements?.length,
  );
}
