import { Pipe, PipeTransform } from '@angular/core';

import { ArticlePriceConditionUnit } from '../models';
import { getArticleStrictPriceLocaleString } from '../utils';

@Pipe({
  name: 'mpcmArticlePriceDetails',
  standalone: true,
})
export class ArticlePriceDetailsPipe implements PipeTransform {
  transform({ baseUnit, quantityOfBaseUnit, unit, unitPrice }: ArticlePriceConditionUnit): string {
    const formattedUnitPrice = getArticleStrictPriceLocaleString(unitPrice);

    return `${formattedUnitPrice} ${unit} (${quantityOfBaseUnit} ${baseUnit})`;
  }
}

