<div [matMenuTriggerFor]="isDisabled() ? null : exportProviderMenu" (click)="$event.stopPropagation()">
  <ng-content />
</div>

<mat-menu #exportProviderMenu="matMenu">
  @for (provider of exportJobProviders(); track provider.exportType) {
    <button mat-menu-item (click)="selectExportType(provider.exportType)">
      {{ provider.displayName }}
    </button>
  }
</mat-menu>
