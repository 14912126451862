import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCardModule } from '@angular/material/legacy-card';

import { CategoriesModule, CategoryType } from '../../../categories';
import { ArticleAttributes, ArticleCategoriesWithProperties } from '../../models';
import { KeyValue } from '../key-value-panel/key-value';
import { KeyValuePanelComponent } from '../key-value-panel/key-value-panel.component';

@Component({
  selector: 'mpcm-category-card',
  standalone: true,
  templateUrl: './category-card.component.html',
  styleUrl: './category-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, MatLegacyCardModule, MatIconModule, CategoriesModule, KeyValuePanelComponent],
})
export class CategoryCardComponent {
  readonly category: InputSignal<ArticleCategoriesWithProperties> = input.required<ArticleCategoriesWithProperties>();
  readonly categoryType: InputSignal<string> = input.required<string>();

  readonly articleAttributes: InputSignal<ArticleAttributes | undefined> = input<ArticleAttributes>();

  readonly expansionStateKeyPrefix: InputSignal<string> = input.required<string>();

  readonly basePath: InputSignal<string[]> = input<string[]>([]);

  protected readonly expansionStateKey: Signal<string> = computed(
    () => `${this.expansionStateKeyPrefix()}-${this.categoryType()}`,
  );

  protected readonly categoryPropertiesKeyValuesList: Signal<KeyValue[]> = computed(() =>
    this.getCategoryPropertiesKeyValuesList(this.category()),
  );

  protected readonly isWholeCategoryHighlighted: Signal<boolean> = computed(() => {
    const categoryId = this.category().categoryId;
    if (!categoryId) {
      return false;
    }
    return !!this.articleAttributes()?.[`categories.${categoryId}`];
  });

  protected readonly areCategoryPropertiesHighlighted: Signal<boolean> = computed(() => {
    if (this.isWholeCategoryHighlighted()) {
      return true;
    }

    const categoryId = this.category().categoryId;
    if (!categoryId) {
      return false;
    }
    return !!this.articleAttributes()?.[`categories.${categoryId}.properties`];
  });

  private getCategoryPropertiesKeyValuesList(category: ArticleCategoriesWithProperties): KeyValue[] {
    const keyValues: KeyValue[] = [];

    const categoryTypeMainPropertyKeyValue: KeyValue | null = this.getCategoryTypeMainPropertyKeyValue(category);

    if (categoryTypeMainPropertyKeyValue) {
      keyValues.push(categoryTypeMainPropertyKeyValue);
    }

    return keyValues.concat(this.getCategoryBasePropertiesKeyValuesList(category));
  }

  private getCategoryTypeMainPropertyKeyValue({ category }: ArticleCategoriesWithProperties): KeyValue | null {
    switch (category.type) {
      case CategoryType.ECLASS:
        return this.getPropertyKeyValue('IRDI', category.irdi);
      case CategoryType.ATC:
        return this.getPropertyKeyValue('DDD-Info', category.info);

      default:
        return null;
    }
  }

  private getCategoryBasePropertiesKeyValuesList({ properties }: ArticleCategoriesWithProperties): KeyValue[] {
    return (properties || [])
      .map(({ featureReadable, featureCode }) => this.getPropertyKeyValue(featureReadable, featureCode))
      .filter((keyValue): keyValue is KeyValue => !!keyValue);
  }

  private getPropertyKeyValue(key: string | undefined, value: string | undefined): KeyValue | null {
    return key && value ? { key, value } : null;
  }
}
