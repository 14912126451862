@if (condition().contract; as contract) {
  @if (contract.extraChargeForSmallQuantities && contract.minimalOrderValue) {
    <div class="condition-ordering-conditions">
      <mpcm-ordering-conditions-field
        class="condition-ordering-conditions__field"
        label="Mindermengenzuschlag"
        [value]="contract.extraChargeForSmallQuantities || 0"
      />

      <mpcm-ordering-conditions-field
        class="condition-ordering-conditions__field"
        label="bei Unterschreitung des minimalen Bestellwertes"
        [value]="contract.minimalOrderValue || 0"
      />

      <span>.</span>
    </div>
  } @else if (contract.minimalOrderValue) {
    <mpcm-ordering-conditions-field label="Minimaler Bestellwert:" [value]="contract.minimalOrderValue" />
  }
}

@if (condition().minimumOrderValue || condition().contract?.minimumOrderValue) {
  <mpcm-ordering-conditions-field
    label="Mindestbestellwert:"
    [value]="
      condition().minimumOrderValue ? condition().minimumOrderValue || 0 : condition().contract?.minimumOrderValue || 0
    "
  />
}
