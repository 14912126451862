import { Pipe, PipeTransform } from '@angular/core';

import { ArticlePriceCondition, ArticlePriceConditionUnit } from '../models';
import { getArticlePriceLocaleString, getArticleStrictPriceLocaleString } from '../utils';

@Pipe({ name: 'mpcmEKGBaseUnitPriceTooltip', standalone: true })
export class EKGBaseUnitPriceTooltipPipe implements PipeTransform {
  transform(priceCondition?: ArticlePriceCondition): string {
    if (!priceCondition || !priceCondition.purchasingGroupConditionUnit) return '';

    const formattedBaseUnitPrice = getArticleStrictPriceLocaleString(
      priceCondition.purchasingGroupConditionUnit.baseUnitPrice,
    );

    const conditionSuffix = priceCondition.isSignedCondition ? 'Gezeichneter Preis' : 'Nichtgezeichneter Preis';

    return `${formattedBaseUnitPrice} ${priceCondition.purchasingGroupConditionUnit.baseUnit} | ${conditionSuffix}`;
  }
}

@Pipe({ name: 'mpcmEKGUnitPriceTooltip', standalone: true })
export class EKGUnitPriceTooltipPipe implements PipeTransform {
  transform(purchasingGroupConditionUnit?: ArticlePriceConditionUnit): string {
    if (!purchasingGroupConditionUnit) return '';

    let price = '';
    const unit = purchasingGroupConditionUnit.unit;
    const quantityOfBaseUnit = purchasingGroupConditionUnit.quantityOfBaseUnit;
    const baseUnit = purchasingGroupConditionUnit.baseUnit;

    if (purchasingGroupConditionUnit.unitPrice) {
      price = getArticlePriceLocaleString(purchasingGroupConditionUnit.unitPrice);
    }

    return `${price} ${unit} (${quantityOfBaseUnit} ${baseUnit})`;
  }
}

@Pipe({ name: 'mpcmClinicBaseUnitPriceTooltip', standalone: true })
export class ClinicBaseUnitPriceTooltipPipe implements PipeTransform {
  transform(clinicPriceConditionUnit?: ArticlePriceConditionUnit): string {
    if (!clinicPriceConditionUnit) return '';

    const formattedBaseUnitPrice = getArticleStrictPriceLocaleString(clinicPriceConditionUnit.baseUnitPrice);

    return `${formattedBaseUnitPrice} ${clinicPriceConditionUnit.baseUnit}`;
  }
}

@Pipe({ name: 'mpcmClinicUnitPriceTooltip', standalone: true })
export class ClinicUnitPriceTooltipPipe implements PipeTransform {
  transform(clinicPriceConditionUnit?: ArticlePriceConditionUnit): string {
    if (!clinicPriceConditionUnit) return '';

    const price = getArticlePriceLocaleString(clinicPriceConditionUnit.unitPrice);
    const unit = clinicPriceConditionUnit.unit;
    const quantityOfBaseUnit = clinicPriceConditionUnit.quantityOfBaseUnit;
    const baseUnit = clinicPriceConditionUnit.baseUnit;

    return `${price} ${unit} (${quantityOfBaseUnit} ${baseUnit})`;
  }
}

@Pipe({ name: 'mpcmAbsolutePriceDifferenceTooltip', standalone: true })
export class AbsolutePriceDifferenceTooltipPipe implements PipeTransform {
  transform(priceCondition?: ArticlePriceCondition): string {
    if (
      !priceCondition ||
      priceCondition.absoluteBasePriceDifference === null ||
      priceCondition.absoluteBasePriceDifference === undefined
    )
      return '';

    const formattedBasePriceDifference = getArticleStrictPriceLocaleString(priceCondition.absoluteBasePriceDifference);

    return `${formattedBasePriceDifference} ${priceCondition.purchasingGroupConditionUnit?.baseUnit}`;
  }
}

@Pipe({ name: 'mpcmRelativePriceDifferenceTooltip', standalone: true })
export class RelativePriceDifferenceTooltipPipe implements PipeTransform {
  transform(priceCondition?: ArticlePriceCondition): string {
    if (
      !priceCondition ||
      priceCondition.relativeDifference === null ||
      priceCondition.relativeDifference === undefined
    )
      return '';

    return priceCondition.relativeDifference.toLocaleString('de-DE', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });
  }
}
