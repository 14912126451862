import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';

import { CopyToClipboardButtonComponent } from '@core/ui';

import { CategoryBase } from '../../models/category-base';
import { CategoryBreadcrumbPipe } from '../../pipes';

@Component({
  selector: 'mpcm-breadcrumb-item',
  standalone: true,
  templateUrl: './breadcrumb-item.component.html',
  styleUrls: ['./breadcrumb-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, NgFor, RouterLink, MatIconModule, CategoryBreadcrumbPipe, CopyToClipboardButtonComponent],
})
export class BreadcrumbItemComponent {
  private _categoriesPath!: CategoryBase;

  get categoriesPath(): CategoryBase {
    return this._categoriesPath;
  }
  @Input()
  set categoriesPath(value: CategoryBase) {
    this._categoriesPath = value;
  }

  /**
   * Variablen zum Anzeigen/Ausblenden der einzelnen Komponenten-Elemente
   * Variables for showing/hiding individual component elements
   */
  @Input() showParents = false;
  @Input() showIcons = false;
  @Input() showAllCategoryCodes = false;
  @Input() showLastCategoryCode = false;
  @Input() showCopyCodeButtons = false;

  @Input() basePath!: string[];

  trackByFn(_index: number, { code }: CategoryBase): string {
    return code;
  }
}
