import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FileExtensionPipe,
  FileNamePipe,
  HumanizeMimeTypePipe,
  IconizeMimeTypePipe,
} from './pipes';

@NgModule({
  imports: [CommonModule],
  declarations: [
    FileExtensionPipe,
    FileNamePipe,
    HumanizeMimeTypePipe,
    IconizeMimeTypePipe,
  ],
  exports: [
    FileExtensionPipe,
    FileNamePipe,
    HumanizeMimeTypePipe,
    IconizeMimeTypePipe,
  ],
})
export class ArticleDetailsSharedModule {}
