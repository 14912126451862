import { NgIf, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { InlineEditComponent } from '@core/ui';

@Component({
  selector: 'mpcm-add-favorites-list-button',
  standalone: true,
  templateUrl: './add-favorites-list-button.component.html',
  styleUrls: ['./add-favorites-list-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgTemplateOutlet,

    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,

    InlineEditComponent,
  ],
})
export class AddFavoritesListButtonComponent {
  @HostBinding('class') readonly class = 'mpcm-add-favorites-list-button';

  @Input() buttonLabel = 'Neue Liste erstellen';

  @HostBinding('class.mpcm-add-favorites-list-button--embedded')
  @Input()
  embedded = false;

  @Output() readonly newListCreate = new EventEmitter<string>();

  inputFieldVisible = false;

  createNewList(name: string): void {
    this.newListCreate.emit(name);
  }
}
