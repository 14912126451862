import {
  ChangeDetectionStrategy,
  Component,
  InputSignal,
  OnInit,
  OutputEmitterRef,
  Signal,
  computed,
  input,
  output,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';

import { ExportFacade, ExportJobProvider, ExportsDataAccessModule } from '@mp/content-manager/exports/data-access';

import { ExportArticlesMenuComponent } from '../export-articles-menu/export-articles-menu.component';

@Component({
  selector: 'mpcm-export-articles-button',
  standalone: true,
  templateUrl: './export-articles-button.component.html',
  styleUrl: './export-articles-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule, MatTooltipModule, MatButtonModule, ExportsDataAccessModule, ExportArticlesMenuComponent],
})
export class ExportArticlesButtonComponent implements OnInit {
  readonly isDisabled: InputSignal<boolean> = input.required<boolean>();

  readonly tooltipText: InputSignal<string | null> = input<string | null>(null);

  protected readonly exportJobProviders: Signal<readonly ExportJobProvider[]> = toSignal(
    this.exportFacade.exportJobProviders$,
    { initialValue: [] },
  );

  protected readonly isExportDisabled: Signal<boolean> = computed(
    () => this.isDisabled() || !this.exportJobProviders().length,
  );

  readonly exportTypeSelect: OutputEmitterRef<string> = output<string>();

  constructor(private readonly exportFacade: ExportFacade) {}

  ngOnInit(): void {
    this.exportFacade.fetchExportJobProviders(true);
  }

  selectExportType(exportType: string): void {
    this.exportTypeSelect.emit(exportType);
  }
}
