import { ChangeDetectionStrategy, Component, InputSignal, Signal, computed, input } from '@angular/core';

import { AgreementInfoListComponent, Graduation, GraduationsComponent } from '@core/ui';

import { ArticleContractAgreement, ArticleContractAgreementGraduation } from '../../../../models';

import { mapAgreementGraduationsToGraduations } from './article-condition-agreement-utils';

@Component({
  selector: 'mpcm-article-condition-agreement',
  standalone: true,
  templateUrl: './article-condition-agreement.component.html',
  styleUrl: './article-condition-agreement.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AgreementInfoListComponent, GraduationsComponent],
})
export class ArticleConditionAgreementComponent {
  readonly agreement: InputSignal<ArticleContractAgreement> = input.required<ArticleContractAgreement>();

  readonly agreementGraduations: Signal<Graduation[]> = computed(() => {
    const agreementGraduations: ArticleContractAgreementGraduation[] | null = this.agreement().graduations;

    return mapAgreementGraduationsToGraduations(agreementGraduations);
  });
}
